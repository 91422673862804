<template>
  <div>
    <v-row no-gutters>
      <SubBar :title="subBarTitle" route_name="gestion_des_structures" />
    </v-row>

    <v-data-table
      :headers="headers"
      :items="affiliations"
      :items-per-page="20"
      class="elevation-1 list__table"
      height="500px"
    >
      <template v-slot:item.STR_NomStructure="{ item }">
        {{ item.STR_NomStructure }}
      </template>

      <template v-slot:item.Z_DateCreationDemande="{ item }">
        <span v-if="item.Z_DateCreationDemande">
          {{ $moment(item.Z_DateCreationDemande).format('DD/MM/YYYY') }}</span
        >
        <span v-else> - </span>
      </template>

      <template v-slot:item.ID_TypeStructure="{ item }">
        <span
          v-if="
            item.ID_TypeStructure &&
              allStructureType.find((s) => s.id == item.ID_TypeStructure)
          "
        >
          {{
            allStructureType.find((s) => s.id == item.ID_TypeStructure)
              .NomTypeStructure
          }}
        </span>
        <span v-else> - </span>
      </template>

      <template v-slot:item.Status="{ item }">
        <span v-if="item.Status == 0"
          ><v-chip small color="primary">Demande en attente</v-chip></span
        >
        <span v-if="item.Status == 1"
          ><v-chip small color="secondary">Avis donné</v-chip></span
        >
        <span v-if="item.Status == 2"
          ><v-chip small color="warning">Demande acceptée</v-chip></span
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top v-if="hasCodeFederal">
          <template v-slot:activator="{ on }">
            <router-link
              :to="{
                name: 'gestion_des_affiliations__primo_affiliation_detail',
                params: { id: item.id },
              }"
            >
              <v-btn v-on="on" class="mx-1" fab x-small color="blue">
                <v-icon :color="'white'">mdi-open-in-new</v-icon>
              </v-btn>
            </router-link>
          </template>
          <span>Voir la demande</span>
        </v-tooltip>
        <v-tooltip top v-if="hasCodeFederal">
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="item.Status == 0"
              v-on="on"
              class="mx-1"
              fab
              x-small
              color="red"
              @click="() => {
                itemToDelete = item.id;
                confirmDialog = true;
              }"
            >
              <v-icon :color="'white'">mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Supprimer la demande</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <YesNo
      id="yesno-dialog"
      title="CONFIRMATION"
      text="Souhaitez-vous vraiment supprimer cette demande d'affiliation ?"
      maxWidth="360"
      :showDialog="confirmDialog"
      @answerYes="removeTempAffiliation"
      @answerNo="confirmDialog = false"
    />
  </div>
</template>

<script>
import SubBar from '@/components/Common/Common__SubBar.vue';
import YesNo from "@/components/utils/modals/yes-no.vue";

import { mapActions, mapGetters } from 'vuex';
import router from '@/router';

import { success_notification, error_notification } from '@/plugins/notifications.js';
import {
  getDepartmentsByStructureId,
} from "@/plugins/structureService";

export default {
  components: { SubBar, YesNo },
  name: 'GestionDesLicences__RenouvelerLicencesEnSerie',

  data: () => ({
    headers: [
      { text: 'Nom Structure', value: 'STR_NomStructure' },
      { text: 'Type de structure', value: 'ID_TypeStructure' },
      { text: 'Saison concernée', value: 'STR_Affiliation.ID_Premiere_Saison' },
      { text: 'Status', value: 'Status' },
      { text: 'Date de la demande', value: 'Z_DateCreationDemande' },
      { text: 'Actions', value: 'actions' },
    ],
    affiliations: [],
    mounted: false,
    allStructureType: [],
    confirmDialog: false,
    itemToDelete: null,
  }),

  async created() {
    this.$emit('setTitle', 'Demandes affiliations en attente');
    await this.setAppTitle({ title: 'Gestion des affiliations' });
    await this.setData();
    console.log('created');

    return;
  },

  methods: {
    ...mapActions('structure', [
      'getAffiliationsTemp',
      'getAffiliationsTempByCt',
      'deleteTempAffiliation',
    ]),
    ...mapActions('basics', [
      'getListStructureType',
      'getDepartementByStructure',
    ]),
    ...mapActions('app', ['setAppTitle']),

    async setData() {
      let affi = await this.getAffiliationsTemp();

      this.allStructureType = await this.getListStructureType();
      // let currentDepartement = parseInt(departement.CodeDepartement);
      const departmentsIds = await getDepartmentsByStructureId(this.currentStructureId);

      // console.log('currentDepartement', currentDepartement);

      let filterDepartementByCt = await this.getAffiliationsTempByCt(
        departmentsIds
      );
      console.log('filterDepartementByCt', filterDepartementByCt);

      if (this.getRole.SlugRole == 'ct_administrateur') {
        this.affiliations = filterDepartementByCt?.STR_AffiliationTemp;
      } else {
        this.affiliations = affi?.STR_AffiliationTemp;
      }

      this.affiliations.forEach((affiliation) => {
        affiliation.STR_Affiliation = JSON.parse(affiliation.STR_Affiliation);
      });

      return;
    },

    async removeTempAffiliation() {
      if (!this.itemToDelete) {
        return false;
      }

      try {
        await this.deleteTempAffiliation(this.itemToDelete);
      } catch (error) {
        console.error(error)
        error_notification('Une erreur est survenue pedant la suppression. Veillez réessayer plus tard');
        return false;
      }

      this.confirmDialog = false;
      await this.setData();
      success_notification('Demande supprimée avec succès');
    },
  },

  computed: {
    ...mapGetters('basics', ['getList']),
    ...mapGetters('structure', ['hasCodeFederal', 'currentStructureId']),
    ...mapGetters('user', ['getRole']),

    subBarTitle() {
      return 'Demandes de primo affiliations';
    },
  },

  mounted() {
    console.log(this.getRole," <=================");
  }
};
</script>
